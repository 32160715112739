@if ($buttonLabel()) {
  <myt-button
    [type]="buttonType"
    [size]="buttonSize"
    [matMenuTriggerFor]="menu"
    class="md:menu__button hidden md:block"
    [ngClass]="$labelCssClasses()"
  >
    {{ $buttonLabel() }}
    <myt-icon name="keyboard_arrow_down" [size]="iconSize.Small" class="myt-button__suffix" />
  </myt-button>
  <myt-button [type]="buttonTypes.Icon" class="text-white md:hidden" [matMenuTriggerFor]="menu">
    <myt-icon name="menu" [size]="iconSize.Small" />
  </myt-button>
} @else if ($textLabel()) {
  <span class="text-title cursor-pointer underline" [matMenuTriggerFor]="menu" [ngClass]="$labelCssClasses()">
    {{ $textLabel() }}
  </span>
} @else {
  <div [matMenuTriggerFor]="menu" (menuOpened)="onMenuOpened.emit()">
    <ng-content select="[customMenuButton]" />
  </div>
}

<mat-menu #menu="matMenu" [xPosition]="positionX()" [class]="'menu__panel ' + $menuPanelCssClasses()" (closed)="onMenuClosed.emit()">
  @for (item of $items(); track item.label) {
    @if (item.externalLink) {
      <a mat-menu-item [href]="item.externalLink">{{ item.label }}</a>
    } @else if (item.link) {
      <button mat-menu-item [routerLink]="item.link">{{ item.label }}</button>
    } @else if (item.chipType) {
      <div class="last:mb-3">
        <myt-chip
          (chipClick)="onItemSelect(item.value || item.label)"
          [customChipClasses]="$menuItemCssClasses() + ' mx-3 mt-3'"
          [interactive]="true"
          [type]="item.chipType"
          [text]="item.label"
        />
      </div>
    } @else {
      <div [ngClass]="{ 'menu__item-with-icon': item.selected }">
        <button mat-menu-item (click)="onItemSelect(item.value || item.label)" [disabled]="item.disabled">
          <div class="flex flex-row items-center">
            @if (item.iconName && item.iconPosition === 'before') {
              <myt-icon [name]="item.iconName" [size]="iconSize.Small" class="mr-2" />
            }
            <div [ngClass]="{ 'text-accent-800': item.selected }" data-role="myt-menu-item-text">{{ item.label }}</div>
            @if (item.selected) {
              <myt-icon name="check" class="text-accent-800 ml-auto pl-3" [size]="iconSize.Small" />
            }
            @if (item.iconName && item.iconPosition === 'after') {
              <myt-icon [name]="item.iconName" [size]="iconSize.Small" />
            }
          </div>
        </button>
      </div>
    }
  }
</mat-menu>
